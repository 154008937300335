import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  MarkdownContent,
  Image,
  Content,
  InternalLink,
  OutboundLink,
} from "@bluefin/components";
import { Grid, Header, Segment, Button, Embed } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import "../style/about.css";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "content_section_header",
                  defaultValue: "About",
                })}
              />
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <div className={"component-section-container home-page ad-hoc-page"}>
            <Grid.Column width={16} textAlign={"center"}>
              <Image
                src={
                  "https://fisherman.gumlet.io/public/2286dd05-ce7e-4212-aad4-b899cc74c6f0/About_Background_1.jpg"
                }
                className={"image hero image-overlay"}
                background={true}
              >
                <Grid
                  className={"home-section-container ad-hoc-section-container"}
                  stackable={true}
                  textAlign={"center"}
                >
                  <Grid.Column width={8} floated={"left"}>
                    <Segment textAlign={"center"} padded={true}>
                      <h2>Meet Our Experts</h2>
                      <Content textAlign={"center"}>
                        <Content.Markup
                          width={16}
                          isMarkdown={false}
                          content={
                            <div className={"ad-hoc-markup"}>
                              Learn about our team of expertly trained, deeply
                              passionate hair stylists in Parker and Lone Tree,
                              CO, committed to bringing your hair dreams to
                              life.
                            </div>
                          }
                          extra={
                            <div className={"ctas-container"}>
                              <Button
                                primary={true}
                                as={InternalLink}
                                to={"/team/"}
                              >
                                Meet The Team
                              </Button>
                            </div>
                          }
                        />
                      </Content>
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Image>
            </Grid.Column>
          </div>
          <Grid
            stackable={true}
            className={"component-section-container additional-text"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={8} textAlign={"center"}>
              <h2>Professional Products</h2>
              <div>
                Our range of professional-grade products caters to diverse hair
                textures, with a special focus on embracing the needs of our
                cherished curl community. Understanding that simplicity often
                reigns supreme, we prioritize offering clean, eco-friendly
                haircare solutions that deliver on their promises without
                jeopardizing your health or wellness.
              </div>
              <Button
                primary={true}
                as={OutboundLink}
                to={"https://shop.saloninteractive.com/store/SahairSalon"}
              >
                Shop Products
              </Button>
            </Grid.Column>
          </Grid>
          <div className={"component-section-container home-page ad-hoc-page"}>
            <Grid.Column width={16} textAlign={"center"}>
              <Image
                src={
                  "https://fisherman.gumlet.io/public/2286dd05-ce7e-4212-aad4-b899cc74c6f0/About_Background_2.jpeg"
                }
                className={"image hero image-overlay"}
                background={true}
              >
                <Grid
                  className={"home-section-container ad-hoc-section-container"}
                  stackable={true}
                  textAlign={"center"}
                >
                  <Grid.Column width={8} floated={"right"}>
                    <Segment textAlign={"center"} padded={true}>
                      <h2>Discover Your Sahair Soulmate!</h2>
                      <Content textAlign={"center"}>
                        <Content.Markup
                          width={16}
                          isMarkdown={false}
                          content={
                            <div className={"ad-hoc-markup"}>
                              Try out our short matchmaking quiz! In less than 5
                              minutes, you will see which stylist is the best
                              fit for your personality and hair type!
                            </div>
                          }
                          extra={
                            <div className={"ctas-container"}>
                              <Button
                                primary={true}
                                as={OutboundLink}
                                to={"https://app.joinmya.com/sahairmainstreet"}
                              >
                                Parker
                              </Button>
                              <Button
                                primary={true}
                                as={OutboundLink}
                                to={"https://app.joinmya.com/sahairlincoln"}
                              >
                                Lone Tree
                              </Button>
                            </div>
                          }
                        />
                      </Content>
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Image>
            </Grid.Column>
          </div>
          <Grid
            stackable={true}
            className={"video-grid"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={8} textAlign={"center"}>
              <Embed
                url={
                  "https://player.vimeo.com/video/948716523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                }
                autoplay={true}
                defaultActive={true}
                className={"about-video"}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
